import { useEffect, useState } from "react";

import { Tab } from "@headlessui/react";

import backgroundImage from "~/components/marketing/assets/images/background-features.jpg";
import screenshotCandidateProfile from "~/components/marketing/assets/images/screenshots/reviewer-candidate-profile.png";
import screenshotCandidatesSubmitted from "~/components/marketing/assets/images/screenshots/reviewer-candidates-submitted.png";
import screenshotCommunication from "~/components/marketing/assets/images/screenshots/reviewer-communication.png";
import screenshotQuickSubmit from "~/components/marketing/assets/images/screenshots/candidate-quicksubmit.png";
import { Container } from "~/components/marketing/lib/Container";
import { cn } from "~/lib/utils";

const features = [
  {
    title: "Simplified Candidate Presentations",
    description:
      "Say goodbye to the uncertainties of email-based submissions — no more wondering if your email got buried or overlooked. Our platform ensures that each candidate is presented directly to the reviewer, organized and easy to track, making the entire process transparent and efficient. With our system, you're always in the loop, confident that every submission gets the attention it deserves.",
    image: screenshotQuickSubmit,
  },
  {
    title: "Interactive Candidate Profiles",
    description:
      "Offer reviewers detailed candidate profiles that facilitate instant feedback. These profiles, showcasing resumes and key qualifications, are designed for quick evaluation and immediate response, accelerating the review process.",
    image: screenshotCandidateProfile,
  },
  {
    title: "Real-Time Collaboration Tools",
    description:
      "Enhance reviewer teamwork with tools that allow them to collaborate in real-time. Share notes, discuss candidates, and make collective decisions quickly and effectively.",
    image: screenshotCandidatesSubmitted,
  },
  {
    title: "Seamless Feedback Communication",
    description:
      "Keep the recruitment conversation flowing with integrated messaging, email, and notification systems, ensuring that all stakeholders are synchronized and informed at every stage.",
    image: screenshotCommunication,
  },
];

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState<"horizontal" | "vertical">(
    "horizontal",
  );

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }: { matches: boolean }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-blue-600 pb-28 pt-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
        // unoptimized
      />
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Fast-Track Candidate Evaluation with Interactive Resumes
          </h2>
          <p className="mt-6 text-lg tracking-tight text-blue-100">
            Empower reviewers to give instant feedback on detailed candidate
            profiles, streamlining the review process for quicker, informed
            decision-making.
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === "vertical"}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={cn(
                        "group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6",
                        selectedIndex === featureIndex
                          ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                          : "hover:bg-white/10 lg:hover:bg-white/5",
                      )}
                    >
                      <h3>
                        <Tab
                          className={cn(
                            "font-display text-lg ui-not-focus-visible:outline-none",
                            selectedIndex === featureIndex
                              ? "text-blue-600 lg:text-white"
                              : "text-blue-100 hover:text-white lg:text-white",
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={cn(
                          "mt-2 hidden text-sm lg:block",
                          selectedIndex === featureIndex
                            ? "text-white"
                            : "text-blue-100 group-hover:text-white",
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        // priority
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  );
}
