import { parseWithZod } from "@conform-to/zod";
import { createId } from "@paralleldrive/cuid2";
import { json, redirect } from "@remix-run/node";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import orderBy from "lodash/orderBy";
import type Stripe from "stripe";
import { z } from "zod";

import { CallToAction } from "~/components/marketing/lib/CallToAction";
import { Faqs } from "~/components/marketing/lib/Faqs";
import { Hero } from "~/components/marketing/lib/Hero";
import { Pricing } from "~/components/marketing/lib/Pricing";
import { PrimaryFeatures } from "~/components/marketing/lib/PrimaryFeatures";
import { SecondaryFeatures } from "~/components/marketing/lib/SecondaryFeatures";
import { Testimonials } from "~/components/marketing/lib/Testimonials";
import { UpdateSignup } from "~/components/marketing/lib/UpdateSignup";
import { stripe } from "~/lib/stripe.server";
import { StripePriceSchema } from "~/lib/user-validation";

export const meta: MetaFunction = () => {
  return [
    { title: "CandidateStream" },
    { name: "description", content: "Welcome to CandidateStream!" },
  ];
};

const PurchaseFormSchema = z.object({
  intent: z.enum(["purchase"]),
  priceId: StripePriceSchema,
});

export const loader = async ({ request }: LoaderFunctionArgs) => {
  // const cookieSession = await userPrefs.getSession(
  //   request.headers.get("Cookie"),
  // );

  // const accountId = cookieSession.get("accountId") ?? "none";

  const [{ data: productList }, { data: pricesList }] = await Promise.all([
    stripe.products.list({ active: true }),
    stripe.prices.list({ active: true }),
  ]);

  const products = orderBy(productList, (product) => product.metadata.order);
  const prices = pricesList.reduce(
    (acc, price) => {
      const productId =
        typeof price.product === "string" ? price.product : price.product.id;
      const interval = price.recurring?.interval ?? "default";

      acc[productId] ??= {};
      acc[productId][interval] = price;

      return acc;
    },
    {} as Record<string, Record<string, Stripe.Price>>,
  );

  return json({
    products,
    prices,
  });
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: PurchaseFormSchema,
  });

  if (submission.status !== "success") {
    return json(
      { result: submission.reply() },
      { status: submission.status === "error" ? 400 : 200 },
    );
  }

  const { intent, priceId } = submission.value;

  const url = new URL(request.url);
  // const cookieSession = await userPrefs.getSession(
  //   request.headers.get("Cookie"),
  // );

  // const accountId = cookieSession.get("accountId") ?? "none";
  const referenceId = createId();

  // create account, use accountId as client_reference_id

  if (intent === "purchase") {
    try {
      const session = await stripe.checkout.sessions.create({
        // customer: account.referenceId,
        client_reference_id: referenceId,
        line_items: [
          {
            price: priceId,
            quantity: 1,
          },
        ],
        subscription_data: {
          trial_period_days: 14,
        },
        mode: "subscription",
        success_url: `${url.origin}/auth/verify?type=stripe-onboarding&refId=${referenceId}`,
        cancel_url: `${url.origin}/#pricing`,
      });

      if (session.url) {
        return redirect(session.url, 303);
      } else {
        console.error("Session setup error", session);

        return json({
          result: { status: "error" },
        } as const);
      }
    } catch (err) {
      console.error("Acct err", err);

      return json({
        result: { status: "error" },
      } as const);
    }
  }
};

export default function Index() {
  const data = useLoaderData<typeof loader>();

  return (
    <>
      <Hero />
      <PrimaryFeatures />
      <SecondaryFeatures />
      <CallToAction />
      <Testimonials />
      <Pricing {...data} />
      <Faqs />
      <UpdateSignup />
    </>
  );
}
