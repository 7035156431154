import backgroundImage from "~/components/marketing/assets/images/background-faqs.jpg";
import { Container } from "~/components/marketing/lib/Container";

const faqs = [
  [
    {
      question: "What is CandidateStream.io?",
      answer:
        "CandidateStream.io is a recruitment platform designed for agencies, streamlining the process of managing candidate submissions and facilitating quicker feedback from hiring managers.",
    },
    {
      question: "How does CandidateStream.io improve the recruitment process?",
      answer:
        "Our platform simplifies candidate management, allowing agencies to add or remove candidates easily, and features a real-time dashboard for timely feedback from hiring managers.",
    },
    {
      question: "Can I integrate CandidateStream.io with other HR software?",
      answer:
        "Yes, CandidateStream.io is built to integrate seamlessly with various HR and recruitment software to enhance your workflow.",
    },
  ],
  [
    {
      question: "Is training provided for new users of CandidateStream.io?",
      answer:
        "We offer comprehensive training and support to ensure your team can maximize the benefits of CandidateStream.io.",
    },
    {
      question: "How does CandidateStream.io ensure data privacy and security?",
      answer:
        "We prioritize data security and privacy with state-of-the-art encryption and compliance with industry-standard data protection regulations.",
    },
    {
      question: "Can CandidateStream.io handle high volumes of candidates?",
      answer:
        "Absolutely. CandidateStream.io is designed to efficiently manage large candidate pools, making it ideal for agencies with high recruitment volumes.",
    },
  ],
  [
    {
      question: "Is there a mobile version of CandidateStream.io available?",
      answer:
        "Yes, CandidateStream.io is accessible on various devices, including a mobile-friendly version for on-the-go recruitment management.",
    },
    {
      question: "What kind of customer support does CandidateStream.io offer?",
      answer:
        "We offer round-the-clock customer support through multiple channels, including phone, email, and live chat, to assist with any queries or issues.",
    },
    {
      question: "How can I get started with CandidateStream.io?",
      answer:
        "Getting started is easy! Sign up on our website, and our team will guide you through the setup process and provide all the necessary training for your team.",
    },
  ],
];

export function Faqs() {
  return (
    <section
      id="faqs"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
        // unoptimized
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can’t find what you’re looking for, email our support team
            and if you’re lucky someone will get back to you.
          </p>
        </div>
        <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
